import React, { useState } from 'react'
import { useSpring, useSprings, animated, useTrail, interpolate } from 'react-spring'
import styled from 'styled-components'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import BezierEasing from 'bezier-easing'
import Img from 'gatsby-image'

import LogoSvg from '../images/logo.svg'
import FacebookSvg from '../images/facebook.svg'
import TwitterSvg from '../images/twitter.svg'
import InstagramSvg from '../images/instagram.svg'

const Menu = () => {
  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          menuItems(where: { location: HEADER_MENU }) {
            edges {
              node {
                connectedObject {
                  ... on WordPress_Page {
                    slug
                  }
                }
                label
              }
            }
          }
          globalOptions {
            globalFields {
              menu {
                phoneNumber
                emailAddress
                address
                featureProjectOrAvailability
                featuredProject {
                  ... on WordPress_Project {
                    slug
                    title
                    projectFields {
                      hero {
                        image {
                          sourceUrl
                          mediaItemId
                          modified
                          imageFile {
                            childImageSharp {
                              fluid(maxWidth: 1500, quality: 60) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                featuredAvailability {
                  ... on WordPress_PortfolioItem {
                    slug
                    title
                    portfolioFields {
                      hero {
                        image {
                          sourceUrl
                          mediaItemId
                          modified
                          imageFile {
                            childImageSharp {
                              fluid(maxWidth: 1500, quality: 60) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                awardLink
                awardLogo {
                  sourceUrl
                  mediaItemId
                  modified
                  altText
                  imageFile {
                    publicURL
                  }
                }
                contactDetailsImage {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 800, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              footer {
                address
                facebookLink
                email
                fieldGroupName
                instagramLink
                phone
                tagline
                twitterLink
              }
            }
          }
        }
      }
    `
  )

  const [toggle, setToggle] = useState(false)
  const [backgroundVisible, setBackgroundVisible] = useState(false)
  const [isMenuAnimating, setIsMenuAnimating] = useState(false)

  const navAnimation = useSpring({
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0, 0%, 0)` : `translate3d(0, 101%, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const navAnimationInner = useSpring({
    from: {
      transform: `translate3d(0, -101%, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0, 0%, 0)` : `translate3d(0, -101%, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const featuredProjectAnimation = useSpring({
    from: {
      transform: `translate3d(-101%, 0, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0%, 0, 0)` : `translate3d(-101%, 0, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const featuredProjectInnerAnimation = useSpring({
    from: {
      transform: `translate3d(101%, 0, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0%, 0, 0)` : `translate3d(101%, 0, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const contactAnimation = useSpring({
    from: {
      transform: `translate3d(101%, 0, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0%, 0, 0)` : `translate3d(101%, 0, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const contactAnimationInner = useSpring({
    from: {
      transform: `translate3d(-101%, 0, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0%, 0, 0)` : `translate3d(-101%, 0, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const awardAnimation = useSpring({
    from: {
      transform: `translate3d(0, 101%, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0, 0%, 0)` : `translate3d(0, 101%, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const awardAnimationInner = useSpring({
    from: {
      transform: `translate3d(0, -101%, 0)`
    },
    to: {
      transform: toggle ? `translate3d(0, 0%, 0)` : `translate3d(0, -101%, 0)`
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0, 0.9, 0.69)
    },
    delay: toggle ? 0 : 500
  })

  const awardSvgAnimation = useSpring({
    from: {
      transform: `translate3d(0, 50px, 0)`,
      opacity: 0
    },
    to: {
      transform: toggle ? `translate3d(0, 0px, 0)` : `translate3d(0, 50px, 0)`,
      opacity: toggle ? 1 : 0
    },
    config: {
      duration: 750,
      easing: toggle ? BezierEasing(0.1, 0.305, 0.24, 1) : BezierEasing(0.76, 0.0, 0.9, 0.7)
    }
  })

  const BackgroundAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: backgroundVisible ? 1 : 0 },
    config: { duration: 0 }
  })

  const navItems = wordPress.menuItems.edges

  const navItemsAnimation = useSprings(
    navItems.length,
    navItems.map((item, i) => ({
      y: toggle ? 0 : 100,
      rotate: toggle ? 0 : 5,
      opacity: toggle ? 1 : 0,
      from: { rotate: 5, y: 100, opacity: 0 },
      config: {
        duration: 500,
        easing: toggle ? BezierEasing(0, 0, 0.58, 1) : BezierEasing(0.42, 0, 1, 1)
      },
      delay: isMenuAnimating ? navItems.length * 80 - i * 80 : i * 80 + 100
    }))
  )

  const projectOrAvailability =
    wordPress.globalOptions.globalFields.menu.featureProjectOrAvailability === 'project'
      ? 'project'
      : 'availability'

  const featuredItem =
    wordPress.globalOptions.globalFields.menu.featureProjectOrAvailability === 'project'
      ? wordPress.globalOptions.globalFields.menu.featuredProject
      : wordPress.globalOptions.globalFields.menu.featuredAvailability

  return (
    <>
      <NavIcon
        aria-label="Toggle Navigation"
        className={toggle ? 'active' : ''}
        onClick={() => {
          setToggle(!toggle)
          if (toggle) {
            setIsMenuAnimating(true)
          } else {
            setIsMenuAnimating(false)
          }
        }}
      >
        <Lines />
      </NavIcon>
      <MenuOuter className={toggle ? 'active' : ''}>
        <Left>
          <FeaturedProject>
            <FeaturedProjectWrapper style={featuredProjectAnimation}>
              <FeaturedProjectInner style={featuredProjectInnerAnimation}>
                <Logo
                  onClick={event => {
                    event.preventDefault()
                    setBackgroundVisible(true)
                    setToggle(false)
                    setIsMenuAnimating(true)
                    setTimeout(() => {
                      navigate('/')
                      setBackgroundVisible(false)
                    }, 1400)
                  }}
                  to="/"
                >
                  <LogoSvg />
                </Logo>
                <Link
                  onClick={event => {
                    event.preventDefault()
                    setBackgroundVisible(true)
                    setToggle(false)
                    setIsMenuAnimating(true)
                    setTimeout(() => {
                      navigate(
                        `${
                          projectOrAvailability === 'project' ? '/projects/' : '/availabilities/'
                        }${featuredItem.slug}`
                      )
                      setBackgroundVisible(false)
                    }, 1400)
                  }}
                  to={`${projectOrAvailability === 'project' ? '/projects/' : '/availabilities/'}${
                    featuredItem.slug
                  }`}
                >
                  <FeaturedProjectText>
                    <p>Featured Project</p>
                    <h4>
                      {featuredItem.title}
                      <br />
                      {featuredItem?.projectFields?.hero?.address}
                    </h4>
                  </FeaturedProjectText>
                  <FeaturedProjectImage
                    loading="eager"
                    fluid={
                      projectOrAvailability === 'project'
                        ? wordPress.globalOptions.globalFields.menu.featuredProject.projectFields
                            .hero.image.imageFile.childImageSharp.fluid
                        : wordPress.globalOptions.globalFields.menu.featuredAvailability
                            .portfolioFields.hero.image.imageFile.childImageSharp.fluid
                    }
                    style={{ position: 'absolute' }}
                  />
                </Link>
              </FeaturedProjectInner>
            </FeaturedProjectWrapper>
          </FeaturedProject>
          <ContactDetails>
            <ContactDetailsWrapper style={contactAnimation}>
              <ContactDetailsInner style={contactAnimationInner}>
                <ContactDetailsText>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: wordPress.globalOptions.globalFields.menu.address
                    }}
                  />
                  <br />
                  <p>T: {wordPress.globalOptions.globalFields.menu.phoneNumber}</p>
                  <p>
                    <a href={`mailto:${wordPress.globalOptions.globalFields.menu.emailAddress}`}>
                      {wordPress.globalOptions.globalFields.menu.emailAddress}
                    </a>
                  </p>
                </ContactDetailsText>
                <ContactDetailsImage
                  loading="eager"
                  fluid={
                    wordPress.globalOptions.globalFields.menu.contactDetailsImage.imageFile
                      .childImageSharp.fluid
                  }
                  style={{ position: 'absolute' }}
                />
              </ContactDetailsInner>
            </ContactDetailsWrapper>
          </ContactDetails>
          <AwardLogo>
            <AwardLogoWrapper style={awardAnimation}>
              <AwardLogoInner style={awardAnimationInner}>
                <animated.a
                  style={awardSvgAnimation}
                  href={wordPress.globalOptions.globalFields.menu.awardLink}
                >
                  <img
                    src={wordPress.globalOptions.globalFields.menu.awardLogo.imageFile.publicURL}
                    alt={wordPress.globalOptions.globalFields.menu.awardLogo.altText}
                  />
                </animated.a>
              </AwardLogoInner>
            </AwardLogoWrapper>
          </AwardLogo>
        </Left>
        <Right>
          <RightWrapper style={navAnimation}>
            <RightInner style={navAnimationInner}>
              <ul>
                {navItemsAnimation.map(({ rotate, y, ...rest }, index) => {
                  const slug =
                    navItems[index].node.connectedObject.slug === 'home'
                      ? ''
                      : navItems[index].node.connectedObject.slug
                  return (
                    <animated.li
                      key={[index]}
                      style={{
                        ...rest,
                        transform: interpolate(
                          [y, rotate],
                          (y, rotate) => `translateY(${y}px) rotate(${rotate}deg)`
                        )
                      }}
                    >
                      <Link
                        activeClassName="active"
                        onClick={event => {
                          event.preventDefault()
                          setBackgroundVisible(true)
                          setToggle(false)
                          setIsMenuAnimating(true)
                          setTimeout(() => {
                            navigate(`/${slug}`)
                            setBackgroundVisible(false)
                          }, 1400)
                        }}
                        to={`/${slug}`}
                        dangerouslySetInnerHTML={{ __html: navItems[index].node.label }}
                      />
                    </animated.li>
                  )
                })}
              </ul>
              <Mobile>
                <ContactDetailsMobile>
                  <p>
                    <span>T : {wordPress.globalOptions.globalFields.footer.phone}</span>{' '}
                    <span>
                      E :{' '}
                      <a href={`mailto:${wordPress.globalOptions.globalFields.footer.email}`}>
                        {wordPress.globalOptions.globalFields.footer.email}
                      </a>
                    </span>
                  </p>
                </ContactDetailsMobile>
                {/* <Social>
                  <a
                    href={wordPress.globalOptions.globalFields.footer.facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookSvg />
                  </a>
                  <a
                    href={wordPress.globalOptions.globalFields.footer.twitterLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterSvg />
                  </a>
                  <a
                    href={wordPress.globalOptions.globalFields.footer.instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramSvg />
                  </a>
                </Social> */}
              </Mobile>
            </RightInner>
          </RightWrapper>
        </Right>
        <Background style={BackgroundAnimation} />
      </MenuOuter>
    </>
  )
}

export default Menu

const Logo = styled(Link)`
  position: absolute;
  top: 4.5rem;
  left: 5rem;
  width: 24rem;
  z-index: 10;
`

const MenuOuter = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  pointer-events: none;
  z-index: 99;

  &.active {
    pointer-events: all;
  }
`

const Left = styled(animated.aside)`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 50%;

  @media (max-width: 650px) {
    display: none;
  }
`

const FeaturedProjectImage = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
  will-change: transform;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 1s ease;
    z-index: 2;
  }
`

const FeaturedProject = styled.section`
  position: relative;
  width: 100%;
  height: 60%;
  z-index: 2;

  @media (max-width: 650px) {
    display: none;
  }

  &:hover {
    ${FeaturedProjectImage} {
      transform: scale(1.05);

      &::before {
        background-color: rgba(213, 101, 27, 0.3);
      }
    }
  }
`

const FeaturedProjectWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const FeaturedProjectInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const FeaturedProjectText = styled.div`
  position: absolute;
  right: 4.5rem;
  bottom: 3.5rem;
  text-align: right;
  z-index: 5;

  p {
    font-family: ${props => props.theme.fonts.butler};
    font-weight: 700;
    font-size: 3.5rem;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  h4 {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #fff;
    margin: 0;
  }
`

const ContactDetails = styled(animated.section)`
  position: relative;
  width: 60%;
  height: 40%;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 650px) {
    display: none;
  }
`

const ContactDetailsWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ContactDetailsInner = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ContactDetailsText = styled.div`
  position: absolute;
  right: 4.5rem;
  bottom: 3.5rem;
  text-align: right;
  white-space: pre-wrap;
  z-index: 5;

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #fff;
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.8s ease;

    &:hover {
      color: ${props => props.theme.colours.orange};
    }
  }
`

const ContactDetailsImage = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;
  }
`

const AwardLogo = styled(animated.section)`
  position: relative;
  width: 40%;
  height: 40%;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 650px) {
    display: none;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
  }
`

const AwardLogoWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const AwardLogoInner = styled(animated.div)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colours.orange};
`

const Right = styled(animated.nav)`
  position: relative;
  width: 50%;
  overflow: hidden;
  z-index: 2;

  @media (max-width: 650px) {
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    margin: 0;
  }

  li {
    margin: 0;
  }

  a {
    font-family: ${props => props.theme.fonts.butler};
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;
    color: #fff;
    transition: color 0.8s ease;

    @media (max-width: 350px) {
      font-size: 5rem;
    }

    &:hover,
    &.active {
      color: ${props => props.theme.colours.orange};
    }
  }
`

const RightWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const RightInner = styled(animated.div)`
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 8%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colours.darkGrey};

  @media (max-width: 650px) {
    padding-left: 14%;
  }
`

const Lines = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-1px);
  display: inline-block;
  cursor: pointer;
  user-select: none;
  transition: all 300ms ease;
  width: 2.7rem;
  height: 2px;
  background-color: #fff;

  @media (max-width: 850px) {
    width: 27px;
  }

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 2.7rem;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;
    transform-origin: 50% 50%;
    transition: top 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1),
      transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1),
      background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1);

    @media (max-width: 850px) {
      width: 27px;
    }
  }

  &:before {
    top: 0.8rem;

    @media (max-width: 850px) {
      top: 8px;
    }
  }

  &:after {
    top: -0.8rem;

    @media (max-width: 850px) {
      top: -8px;
    }
  }
`

const NavIcon = styled.button`
  position: fixed;
  top: 50vh;
  right: 8px;
  width: 6.8rem;
  height: 6.8rem;
  background-color: ${props => props.theme.colours.orange};
  transform: translateY(-50px);
  user-select: none;
  border: none;
  cursor: pointer;
  z-index: 100;

  @media (max-width: 850px) {
    right: 3px;
    top: 0;
    transform: translateY(0);
    right: 0;
    width: 68px;
    height: 68px;
  }

  &:hover,
  &:focus {
    outline: 0;
    .burger-lines {
      background-color: #fff;

      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }

  &.active {
    ${Lines} {
      background: transparent;

      &:before,
      &:after {
        transition: top 300ms cubic-bezier(0.165, 0.84, 0.44, 1),
          transform 300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
        top: 0;
        width: 2.7rem;

        @media (max-width: 850px) {
          width: 27px;
        }
      }

      &:before {
        transform: rotate3d(0, 0, 1, 45deg) scale(1.1);
      }

      &:after {
        transform: rotate3d(0, 0, 1, -45deg) scale(1.1);
      }
    }
  }
`

const Mobile = styled(animated.aside)`
  position: absolute;
  bottom: 4%;
  left: 8%;

  @media (max-width: 650px) {
    left: 14%;
  }
`

const Social = styled.div`
  justify-content: flex-end;
  list-style-type: none;

  @media (min-width: 650px) {
    display: none;
  }

  svg {
    width: 3rem;
    height: 3rem;
    margin-left: 1.5rem;
    transition: opacity 0.3s ease;

    @media (max-width: 650px) {
      width: 4rem;
      height: 4rem;
      margin-left: 0;
      margin-right: 2rem;
    }

    &:hover {
      opacity: 0.4;
    }
  }
`

const ContactDetailsMobile = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: 650px) {
    display: none;
  }

  p {
    font-family: ${props => props.theme.fonts.maison};
    font-size: 1.7rem;
    line-height: 2;
    font-weight: 400;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;

    a {
      font-family: ${props => props.theme.fonts.maison};
      font-size: 1.7rem;
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
      transition: color 0.8s ease;

      &:hover {
        color: ${props => props.theme.colours.orange};
      }
    }
  }

  span {
    &:first-child {
      margin-right: 15px;
    }

    @media (max-width: 650px) {
      display: block;
    }
  }
`

const Background = styled(animated.aside)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colours.darkGrey};
`
