const theme = {
  colours: {
    black: '#000000',
    white: '#FFFFFF',
    orange: '#D5651B',
    darkGrey: '#242424',
    lightGrey: '#707070'
  },
  fonts: {
    butler: `'Butler', serif`,
    maison: `'Maison', sans-serif`
  }
}

export default theme
