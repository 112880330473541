import React, { useState, createContext, useEffect } from 'react'

export const PreloaderContext = createContext()

export const PreloaderProvider = ({ children }) => {
  const [preloaderStatus, setPreloaderStatus] = useState({
    shouldPlay: undefined,
    finished: undefined,
    hasSession: undefined
  })

  useEffect(() => {
    if (sessionStorage.getItem('preloaderStatus') === 'played') {
      setPreloaderStatus({
        shouldPlay: false,
        finished: true,
        hasSession: true
      })
    } else {
      setPreloaderStatus({
        shouldPlay: true,
        finished: false,
        hasSession: false
      })
    }
  }, [])

  return (
    <PreloaderContext.Provider value={[preloaderStatus, setPreloaderStatus]}>
      {children}
    </PreloaderContext.Provider>
  )
}
