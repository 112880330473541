import React from 'react'
import { PreloaderProvider } from './src/components/context/preloader-context'

const wrapWithPreloaderProvider = ({ element }) => <PreloaderProvider>{element}</PreloaderProvider>

export const wrapRootElement = wrapWithPreloaderProvider

// Reset the body colour on route change.
// Each page should start grey but if the body background
// colour was previously set, it would persist across
// route changes.
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    document.documentElement.style.setProperty('--transition', 'background-color 0s linear')
  }

  setTimeout(() => {
    document.documentElement.style.setProperty('--primary', '#ffffff')
    document.documentElement.style.setProperty('--secondary', '#242424')
  }, 1)

  setTimeout(() => {
    document.documentElement.style.setProperty('--transition', 'background-color 0.8s linear')
  }, 1000)

  // Clear the local storage item for projects category if we
  // do not land on a project page. If we do land on a project
  // page, we want to keep the category information in case
  // the user navigates back to the main projects page
  if (!location.pathname.includes('/projects/')) {
    if (!location.pathname.includes('/portfolio')) {
      sessionStorage.removeItem('category')
    }
  }
}
