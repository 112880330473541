import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import { useSpring, useChain, animated } from 'react-spring'
import BezierEasing from 'bezier-easing'
import { PreloaderContext } from '../context/preloader-context'

import LogoSvg from '../../images/logo.svg'

const Preloader = () => {
  const [preloaderStatus, setPreloaderStatus] = useContext(PreloaderContext)
  const loadingBarRef = useRef()
  const logoRef = useRef()
  const innerRef = useRef()
  const outerRef = useRef()

  const OuterAnimation = useSpring({
    ref: outerRef,
    from: { transform: 'translate3d(0%, 0, 0)' },
    to: { transform: 'translate3d(100%, 0, 0)' },
    config: {
      duration: 1200,
      easing: BezierEasing(0.785, 0.135, 0.15, 0.86)
    }
  })

  const InnerAnimation = useSpring({
    ref: innerRef,
    from: { opacity: 1 },
    to: { opacity: 0 },
    onRest: () => {
      sessionStorage.setItem('preloaderStatus', 'played')
      setPreloaderStatus({
        shouldPlay: false,
        finished: true
      })
    }
  })

  const LogoAnimation = useSpring({
    ref: logoRef,
    from: { opacity: 0 },
    to: { opacity: 1 }
  })

  const LoadingBarAnimation = useSpring({
    ref: loadingBarRef,
    from: { transform: 'scaleX(0)' },
    to: { transform: 'scaleX(1)' },
    config: {
      duration: 1000,
      easing: BezierEasing(0.55, 0.085, 0.68, 0.53)
    }
  })

  useChain(preloaderStatus.shouldPlay ? [logoRef, loadingBarRef, innerRef, outerRef] : [])

  return (
    <>
      {!preloaderStatus.hasSession && (
        <PreloaderContainer>
          <PreloaderOuter style={OuterAnimation}>
            <PreloaderInner style={InnerAnimation}>
              <Logo style={LogoAnimation}>
                <LogoSvg />
              </Logo>
              <LoadingBar>
                <LoadingBarFill style={LoadingBarAnimation} />
              </LoadingBar>
            </PreloaderInner>
          </PreloaderOuter>
        </PreloaderContainer>
      )}
    </>
  )
}

export default Preloader

const PreloaderContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  pointer-events: none;
  z-index: 105;
`

const PreloaderOuter = styled(animated.aside)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: ${props => props.theme.colours.darkGrey};
`

const PreloaderInner = styled(animated.figure)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled(animated.div)`
  width: 20%;

  @media (max-width: 1400px) {
    width: 30%;
  }

  @media (max-width: 1000px) {
    width: 50%;
  }
`

const LoadingBar = styled.div`
  width: 30%;
  height: 1px;
  margin-top: 3vh;

  @media (max-width: 1400px) {
    width: 40%;
  }

  @media (max-width: 1000px) {
    width: 60%;
  }
`

const LoadingBarFill = styled(animated.div)`
  width: 100%;
  height: 1px;
  background-color: #fff;
  transform-origin: left;
  will-change: transform;
`
