import { createGlobalStyle } from 'styled-components'
import { resetStyles } from '../styles/reset'

const GlobalStyle = createGlobalStyle`
  ${resetStyles()}
  [data-scrollbar] {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
  }  
  :root {
    --primary: #ffffff;
    --secondary: #242424;
    --transition: background-color 0.8s linear;
  }
  html {
    font-size: 10px;
    overflow: hidden;
    max-width: 100vw;
  }
  @media (min-width: 1700px) {
    html {
      font-size: 13px;
    }
  }
  @media (max-width: 1000px) {
    html {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    html {
      font-size: 7px;
    }
  }
  body {
    font-family: ${props => props.theme.fonts.butler};
    font-weight: 300;
    line-height: 1.2;
    background-color: ${props => props.theme.colours.darkGrey};
    background-color: var(--secondary);
    transition: var(--transition);
    overflow: hidden;
    max-width: 100vw;
  }
  body.has-touch [data-scrollbar] {
    height: auto;
    width: 100vw;
  }
  html.has-touch {
    overflow-y: scroll;
  }
  body.grey {
    background-color: ${props => props.theme.colours.darkGrey};
  }
  h1, h2, h3, li {
    font-family: ${props => props.theme.fonts.butler};
    color: #fff;
  }
  a {
    color: ${props => props.theme.colours.orange};
  }
  p {
    font-family: ${props => props.theme.fonts.maison};
    font-size: 1.6rem;
    line-height: 1.6;
    color: ${props => props.theme.colours.orange};
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1000px) {
    p {
      font-size: 2rem;
    }
  }
  .container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 119rem;
    padding: 0 3rem;
  }
  @media (max-width: 650px) {
    .container {
      padding: 0 4rem;
    }
  }
  .main {
    overflow: hidden;
  }
  @media (max-width: 650px) {
    .scrollbar-track.scrollbar-track-y,
    .scrollbar-track .scrollbar-thumb {
      width: 3px;
    }
  }
  ::selection {
    background: ${props => props.theme.colours.orange};
    color: #fff;
  }

  .scrollbar-track-x {
    display: none !important;
  }
`

export default GlobalStyle
