import React, { useEffect, useCallback } from 'react'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Menu from './menu'
import Transition from './transition'
import GlobalStyle from './global-styles'
import { PreloaderProvider } from './context/preloader-context'
import { TransitionProvider } from './context/transition-context'

import Preloader from './animations/preloader'

const PageWrap = ({ children }) => {
  const updateVh = useCallback(() => {
    if (typeof window !== `undefined`) {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  }, [])

  useEffect(() => {
    updateVh()
    window.addEventListener('resize', updateVh)

    return () => {
      window.removeEventListener('resize', updateVh)
    }
  }, [updateVh])

  return (
    <ThemeProvider theme={theme}>
      <PreloaderProvider>
        <TransitionProvider>
          <GlobalStyle />
          <Transition />
          <Preloader />
          <Menu />
          {children}
        </TransitionProvider>
      </PreloaderProvider>
    </ThemeProvider>
  )
}
export default PageWrap
