import React, { useContext } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import BezierEasing from 'bezier-easing'
import { TransitionContext } from './context/transition-context'

const Transition = () => {
  const [transitionActive] = useContext(TransitionContext)

  const maskAnimation = useSpring({
    opacity: transitionActive ? 1 : 0,
    pointerEvents: transitionActive ? 'all' : 'none',
    from: {
      pointerEvents: 'none'
    },
    config: {
      duration: 500,
      easing: BezierEasing(0.23, 1.0, 0.32, 1.0)
    }
  })

  return <PageCover style={maskAnimation} />
}

export default Transition

const PageCover = styled(animated.aside)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${props => props.theme.colours.darkGrey};
  z-index: 90;
`
