// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-availabilities-js": () => import("./../src/templates/availabilities.js" /* webpackChunkName: "component---src-templates-availabilities-js" */),
  "component---src-templates-portfolio-js": () => import("./../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-single-press-js": () => import("./../src/templates/single-press.js" /* webpackChunkName: "component---src-templates-single-press-js" */),
  "component---src-templates-single-project-js": () => import("./../src/templates/single-project.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-portfolio-js": () => import("./../src/templates/single-portfolio.js" /* webpackChunkName: "component---src-templates-single-portfolio-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

